.hero {
  margin: 0;

  &__container {
    @extend .grid;
    padding-top: 0;
  }

  &__back-link {
    display: inline-block;
    background-color: $white;
    color: $black;
    text-decoration: none;
    padding: 0.5rem 0.75rem 0.5rem 1.5rem;
    margin: 1rem 0 0 -1.5rem;
    border-radius: 0 5px 5px 0;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: "\2190   ";
    }

    +.hero__heading {
      margin-top: 0.75rem;
    }
  }

  &__strap {
    font-size: 1.5rem;
    margin: 0.5rem 0 1rem;
  }

  &--home {
    margin-bottom: 2rem;

    .hero__content {
      @media (min-width: $tablet) {
        padding: 0 1.5rem;
      }
    }
  }


  &__content {
    grid-column-start: 1;
    grid-column-end: span 4;
    background-color: $pink-main;
    padding: 0 1rem 0.5rem 1rem;
    position: relative;

    &:before {
      @extend .pattern--red;
      content: "";
      position: absolute;
      top: 0;
      left: -30vw;
      z-index: -1;
      width: 100vw;
      height: 130%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      background-color: inherit;
      width: 100vw;
      right: -90vw;
      z-index: -1;
    }


    @media (min-width: $tablet) {
      grid-column-end: span 12;
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }

  &__heading {
    font-size: 2rem;

    @media (min-width: $tablet) {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }
  }
}

// Search 
.form {
  background: $white;
  border: 4px solid $pink-main;
  padding: 0.75rem;
  display: inline-block;
  margin: 0 0 -2rem;

  &__label {
    font-weight: $weight-bold;
    padding: 0.25rem;
    display: block;
    font-size: 1.5rem;
    margin: 0;
  }

  &__search-container {
    border-radius: 100px;
    border: 2px solid $button-bg;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    padding: 0;
  }

  &__text-input {
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    border: none;
    max-width: 100%;
    flex-grow: 2;
    border-radius: 50px 0 0 50px;
    min-width: 50vw;

    @media (min-width: $tablet) {
      width: 30rem;
      min-width: 30rem;
    }

    @media (min-width: 1280px) {
      width: 40rem;
    }

  }

  &__search-submit {
    @extend .button;
    position: relative;
    padding-right: 3rem;
    border-radius: 0 50px 50px 0;

    @media (min-width: $tablet) {
      border-radius: 50px;
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='28' viewBox='0 0 26 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11ZM16.755 20.3763C15.0807 21.4061 13.1097 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 13.8795 20.8936 16.5006 19.0827 18.4613L25.5607 24.9393C26.1464 25.5251 26.1464 26.4749 25.5607 27.0607C24.9749 27.6464 24.0251 27.6464 23.4393 27.0607L16.755 20.3763Z' fill='white'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 1.25rem;
      height: 1.25rem;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}