a,
button,
.olli-vis,
select.dropdown,
.olli:focus-within,
.form__text-input,
.form__search-submit {
  @include a11y-highlight;
}

.olli-vis .focus {
  background-color: none;

  * {
    background-color: none;
  }
}
