@keyframes responding {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.search-chat {
  grid-column-start: 1;
  grid-column-end: span 4;

  @media (min-width: $tablet) {
    grid-column-end: span 12;
  }

  &__input {
    max-width: 95%;
    margin: 0 auto;
    position: fixed;
    bottom: 0.5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $tablet) {
      max-width: 80%;
      bottom: 2rem;
    }
  }


  &__submit {
    @extend .button;
    position: relative;
    border-radius: 0 50px 50px 0;

    @media (min-width: $tablet) {
      border-radius: 50px;
    }
  }

  &__area {
    margin: 2.5rem 0 5rem;
  }

  .chat-message {
    width: 100%;
    padding: 2rem 2rem 2rem 9rem;
    box-sizing: border-box;
    position: relative;
    line-height: 1.5;

    &:last-of-type {
      padding-bottom: 5rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:before {
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: 1rem;
      border-radius: 3rem;
      width: 5rem;
      text-align: center;
    }

    .responding {
      animation: responding 1s ease-in-out;
      animation-iteration-count: infinite;
    }

    &--user {
      background-color: #eee;
      border-radius: 5px;


      &:before {
        content: "You";
        background-color: $light-blue;
        font-weight: $weight-bold;
      }
    }

    &--response {
      background-color: #fff;
      white-space: pre-wrap;

      &:before {
        content: "AT2030";
        background-color: $pink-main;
      }
    }

    // Text and Styling / spacing etc.
    p {
      margin-bottom: 1rem;
      margin-top: 0;
    }

    img {
      margin-top: 1rem;
      max-width: 100%;
      height: auto;
    }

  }
}

// Empty State
.empty-chat {
  background-color: #fafafa;
  padding: 2rem;
  text-align: center;
}

.clear-chat-link {
  margin-top: 0.5rem;
  display: inline-block;
}