.button {
  padding: 1rem;
  border-radius: 50px;
  background: $button-bg;
  color: $white;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

.hyperlink {
  appearance: none;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  text-decoration: underline;
  color: $hyperlink;
}

.clear-filters {
  align-self: flex-end;
  display: none;

  &.active {
    display: block;
  }
}