// Map
.map {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;

  &__leaflet {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 40rem;

    @media (min-width: $tablet) {
      height: 45vmax;
      min-height: 35rem;
      max-height: 50rem;
    }

    @media (min-width: $max-width) {
      @include not-w-full-extended;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    .hyperlink {
      text-align: right;
      flex-basis: 35%;
    }
  }

  &__instructions {
    min-width: 100%;
    margin: 0 0 1rem;
    text-align: left;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    font-family: $font-main;
    line-height: 160%;
    z-index: 600;
    flex-basis: 60%;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42.5vmax;
    min-height: 25rem;
    max-height: 35rem;
    border: 1px solid #BEBEBE;

    @media (min-width: $tablet) {
      min-height: 30rem;
      max-height: 45rem;
    }
  }

  &__loading-research {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 500;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
    background: #FFF;
    border-bottom: 1px solid $graph-pink;
  }

  &__filters-label {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    margin: 1rem 1rem 1rem 0;
    font-weight: 500;
  }


  &__filters-keys {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0.375rem 0.5rem;
    margin-right: 0.625rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: auto;
    border-radius: 27px;
    border: 2px solid $graph-blue;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--focussed {
      @include a11y-highlight(false);
    }
  }

  &__filter-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
    background: #FFF;
    border: 2px solid $graph-blue;
    border-radius: 100%;
    transition: all 50ms ease-in-out;
    transition-delay: 100ms;

    svg {
      transform: scale(0);
      transition: all 100ms ease-in-out;
      transition-delay: 0ms;
    }

    &--active {
      background: #50ACC8;
      transition-delay: 0ms;

      svg {
        transform: scale(1);
        transition-delay: 50ms;
      }
    }
  }

  &__filter-input {
    width: 0px;
  }

  &__filters-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0.375rem 0.5rem;
    margin-right: 0.625rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: auto;
    border-radius: 27px;
    background: #FFF;
    border: 2px solid $pink-main;
    cursor: pointer;

    &:disabled {
      border: 2px solid $light-blue;
      cursor: not-allowed;
    }
  }

  &__popups {
    position: absolute;
    inset: 0;
    top: 50%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
    z-index: 9999;
    pointer-events: none;

    &--visible {
      pointer-events: auto;
    }
  }

  &__popup {
    padding: 1rem;
    width: 100%;
    background: #FFF;
    border: 2px solid #50ACC8;
    border-radius: 5px;

    @media (min-width: $tablet) {
      width: 33%;
      min-width: 400px;
    }
  }

  &__popup-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.125rem;
    margin-bottom: 1.875rem;
    text-align: left;
  }

  &__popup-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.125rem;
  }

  &__popup-label {
    margin: 0;
    margin-bottom: 0.625rem;
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 43px;
  }

  &__popup-description {
    margin: 0;
  }

  &__popup-close {
    padding: 0;
    color: #5E1719;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    border-radius: 100%;
  }

  &__popup-data {
    font-size: 1.125rem;
  }

  &__popup-data-type {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.125rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__popup-data-type-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
    border: 1px solid currentColor;
    border-radius: 5px;

    &--active {
      background: #E5FFFD;
      color: $graph-teal;
      border: 1px solid $graph-teal;
    }

    &--inactive {
      background: #FEF1E7;
      color: $graph-orange;
      border: 1px solid $graph-orange;
    }
  }

  &__popup-bottom {
    display: flex;
    margin-top: 1.5rem;
  }

  &__popup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 12px;
    background: #5E1719;
    color: #FFF;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: $font-main;
    line-height: 21px;
    text-decoration: none;
    border: none;
    border-radius: 50px;
    appearance: none;
    cursor: pointer;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
    background: #FFF;
    border-radius: inherit;
    overflow: hidden;
    @include a11y-highlight;

    .map__popup-button {
      color: #FFF;
    }
  }

  .leaflet-tile-pane {
    >.leaflet-layer {
      transition: all 350ms ease-out;
    }
  }

  .leaflet-tile {
    .leaflet-interactive {
      transition: all 75ms ease-out;
    }
  }

  .leaflet-bottom.leaflet-left {
    .leaflet-control-zoom {
      margin-left: 1rem;
      margin-bottom: 1rem;
      border: none;
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    box-shadow: 0px 1px 4px rgba(#000, 0.25);
    @include a11y-highlight;

    span {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      color: transparent;
      font-size: 0;
    }
  }

  .leaflet-control-zoom-in {
    margin-bottom: 0.75rem;

    span {
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2515 13.75V25H13.7515V13.75L25.0015 13.75V11.25L13.7515 11.25V3.05176e-05H11.2515V11.25L0.0015261 11.25L0.00152588 13.75L11.2515 13.75Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }

  .leaflet-control-zoom-out {
    span {
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='3' viewBox='0 0 26 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0015261 0.250031L25.0015 0.250033V2.75003L0.00152588 2.75003L0.0015261 0.250031Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}
}