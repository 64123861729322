.generic-text {
  margin: 1rem 0 0 0;
  padding: 0 1rem;
  grid-column-start: 1;
  grid-column-end: span 4;

  &.right--align {
    grid-column-start: 1;
    grid-column-end: span 4;
    margin-top: 0;
  }

  @media (min-width: $tablet) {
    grid-column-end: 8;
    margin: 2rem 0;
    grid-row-start: 1;


    &.right--align {
      grid-column-start: 5;
      grid-column-end: span 8;
      margin: 1.5rem 0;
    }
  }
}

.stat-container {
  @media (min-width: $tablet) {
    grid-row-start: 1;
  }
}

p {
  line-height: 1.6;
}

.abstract {
  background-color: #E0F0F5;
  border: 1px solid $graph-blue;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 3rem;
  display: block;
  line-height: 1.4;
  position: relative;

  p {
    margin: 0;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0.75rem;
    top: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    background-image: url("data:image/svg+xml,%3Csvg width='731' height='731' viewBox='0 0 731 731' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M365.667 0.140625C567.347 0.140625 730.854 163.661 730.854 365.327C730.854 567.007 567.334 730.514 365.667 730.514C163.987 730.514 0.480469 566.994 0.480469 365.327C0.480469 163.647 164 0.140625 365.667 0.140625ZM393.516 134.767C427.866 134.767 455.714 162.617 455.714 196.965C455.714 231.235 427.864 259.09 393.516 259.09C359.168 259.09 331.391 231.241 331.391 196.965C331.391 162.615 359.167 134.767 393.516 134.767ZM288.09 293.727H417.934C438.543 293.727 444.294 311.347 440.48 324.863C436.6 338.301 399.939 532.29 412.855 554.17C425.699 576.045 455.491 539.758 465.792 539.159C476.022 538.487 483.787 547.972 478.188 562.977C472.589 577.909 439.808 610.987 370.819 610.987C301.83 610.987 278.231 553.493 289.803 494.357C301.376 435.221 316.98 361.898 317.876 351.823C318.699 341.745 317.356 328.678 300.85 328.829C294.876 328.829 289.053 328.678 284.126 327.782C260.23 323.303 266.506 293.136 288.084 293.735L288.09 293.727Z' fill='%233CA0BE'/%3E%3C/svg%3E%0A");
  }
}

.stat-container {
  display: none;
}

.stat-container.active {
  background-color: #CCEDEB;
  border: 1px solid $graph-teal;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 3rem;
  margin-top: 1rem;
  display: block;
  line-height: 1.4;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0.75rem;
    top: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    background-size: cover;
    background-position: center center;
    background-repeat: none;
    background-image: url("data:image/svg+xml,%3Csvg width='95' height='96' viewBox='0 0 95 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1101_2)'%3E%3Cpath d='M56.1153 0.027402C53.2153 -0.272598 50.7153 1.9274 50.7153 4.8274V39.5274C50.7153 42.2274 52.9153 44.3274 55.5153 44.3274H90.1153C93.0153 44.3274 95.3153 41.8274 94.9153 38.9274C92.5153 18.6274 76.4153 2.5274 56.1153 0.027402Z' fill='%2300A79D'/%3E%3Cpath d='M78.0154 54.8276C71.5154 54.8276 52.9154 54.8276 44.4154 54.8276C42.0154 54.8276 40.1154 52.9276 40.1154 50.5276V17.0276C40.1154 15.8276 39.1154 14.7276 37.8154 14.8276C16.4154 16.0276 -0.584593 34.0276 0.0154069 56.0276C0.615407 77.0276 18.0154 94.4276 39.0154 95.0276C60.9154 95.6276 79.0154 78.6276 80.2154 57.1276C80.3154 55.9276 79.3154 54.8276 78.0154 54.8276Z' fill='%2300A79D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1101_2'%3E%3Crect width='95' height='96' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  // Contained elements

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding-left: 1.5rem;

    li {
      margin-bottom: 1rem;

      &::marker {
        color: $graph-teal;
      }

      a {
        font-size: 0.75rem;
        display: block;
      }
    }
  }

}

.publications-heading {
  grid-column-start: 1;
  grid-column-end: 4;

  @media (min-width: $tablet) {
    grid-column-end: 12;
    margin-top: 0;
  }
}