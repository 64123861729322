.grid {
  display: grid;
  max-width: $max-width;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  padding: 1rem;

  @media only screen and (min-width: $tablet) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    margin: 0 auto;
  }
}

// Break out of grid.
.w-full-extended {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: 50vw;
}

// Undo w-full-extended properties.
.not-w-full-extended {
  @include not-w-full-extended;
}
