.header {
  border-bottom: 1px solid #ddd;

  &__main {
    @extend .grid;
  }

  &__back-to-main-site {
    width: 100%;
    background-color: $black;
    height: 2rem;
  }
}

.back-to-main-site {
  &__container {
    max-width: $max-width;
    margin: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    @media (min-width: $max-width) {
      margin: 0 auto;
    }
  }

  &__link {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: "\2190   ";
    }
  }
}

.nav-main {
  background-color: $bg;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: span 4;

  @media (max-width: ($tablet - 1)) {
  // Nav Toggle Styling (only mobile)
    &__toggle {
      appearance: none;
      padding: 0.75rem;
      border: 2px solid $pink-main;
      border-radius: $border-radius;
      font-size: 1em;
      background: transparent;
      color: $font-dark;
      transition: background-color 0.2s, color 0.2s;

      &.nav-open {
        background-color: $button-bg;
        color: white;
      }
    }

    // Nav Link Styling (only mobile)
    &__links {
      display: none;

      &.open {
        display: inline;
        position: absolute;
        top: 11.5rem;
        left: 0;
        width: 100%;
        padding: 1rem 0;
        background-color: $button-bg; 
        z-index: 10;
      }
    }

    &__link {
      display: block;
      padding: 1rem;
      border-bottom: 1px white;
      color: $white;
      max-width: 90%;
      margin: 0 auto;
      border-radius: $border-radius;
      position: relative;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: " \2192";
      }

      &.active {
        border: 1px solid $pink-main;
        font-weight: $weight-bold;

        &::after {
          content: "";
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: $tablet) {
    grid-column-end: span 12;

    &__toggle {
      display: none;
    } 

    &__link {
      text-decoration: none;
      color: $font-dark;
      padding: 0.75rem;
      border-radius: 0;
      transition: background-color 0.3s;
  
      &:hover {
        border-bottom: 5px solid $pink-main;
      }
  
      &.active {
        border-bottom: 5px solid $pink-main;
        font-weight: $weight-bold;
      }
    }
  }

 

  &__logo {
    height: 5.5rem;
    padding: 1rem 0;
    position: relative;

    &:after {
      content: "Insights Portal";
      position: absolute;
      height: 44px;
      font-size: 20px;
      font-weight: $weight-bold;
      border-left: 1px solid $pink-main;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 1rem;
      margin-left: 1rem;
      white-space: nowrap;
      color: $black;
      padding-top: 1rem;
    }

    svg {
      height: 100%;
      width: auto;
    }
  }
}
