.dropdown-container {
  grid-column-start: 1;
  grid-column-end: span 4;
  margin: 2rem 0 0 0;

  @media (min-width: $tablet) {
    padding: 1rem 0;
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.dropdown-label {
  display: block;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: $weight-bold;
}

.dropdown {
  text-transform: capitalize;
  max-width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  border-radius: 50px;
  background: $button-bg;
  color: $white;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.439453 3.06077L2.56077 0.939453L11.0001 9.37879L19.4395 0.939453L21.5608 3.06077L11.0001 13.6214L0.439453 3.06077Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: 95% center;
  background-repeat: no-repeat;
  width: 100%;
  margin-bottom: 1rem;


  &--secondary {
    background: $white;
    color: $button-bg;
    border: 2px solid $button-bg;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 3.06077L2.12132 0.939453L10.5607 9.37879L19 0.939453L21.1213 3.06077L10.5607 13.6214L0 3.06077Z' fill='%235E1719'/%3E%3C/svg%3E%0A");
    background-position: 95% center;
    background-repeat: no-repeat;
  }
}