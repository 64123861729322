.footer {

  .color-wrapper {
    background-color: $grey-dark;
  }

  .partners-link {
    grid-column-end: span 4;
    text-align: center;
    margin: 1rem 0 3rem;

    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }
  }
  
  .funding-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 50%;
    margin-top: 3em;
    grid-column-end: span 4;

    @media (min-width: $tablet) {
      grid-column-end: span 6;
    }
  }

  .home-partners-logo {
    height: 10rem;
    width: auto;
  }

  .other-sites-footer {
    color: $white;

    h3 {
      color: $white;
      grid-column-end: span 4;
      margin-bottom: 0;

      @media (min-width: $tablet) {
        grid-column-end: span 12;
      }
    }
  }

  .no-list-format {
    padding-left: 0;
    grid-column-end: span 4;

    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }

    li {
      list-style-type: none;
      margin-bottom: 0.25rem;

      a {
        color: $white;
      }
    }
  }

  &__links {
    color: $white;

    .socials,
    .links {
      grid-column-end: span 4;
      margin-bottom: 1rem;
    }

    .socials {
      display: flex;
      @media (min-width: $tablet) {
        grid-column-end: span 4;
      }
    }

    .links {
      @media (min-width: $tablet) {
        grid-column-end: span 8;
      }
    }

    .footer-icon {
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      justify-content: center;
      margin-right: 1rem;
      color: white !important;

      &:hover {
        text-decoration: underline;
      }

      i {
        font-size: 24px;
        width: 100%;
        text-align: center;
        text-decoration: none;
        margin-bottom: 0.25rem;
      }
    }

    .footer-item {
      margin: 0 1rem 0.5rem 0;
      display: inline-block;
    }

    a {
      color: $white;
    }
  }

  .copyright {
    font-size: 0.75rem;
    color: $white;

    span {
      grid-column-end: span 4;
      padding: 0.5rem 0;
      text-align: center;

      @media (min-width: $tablet) {
        grid-column-end: span 12;
      }
    }
  }
}