@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

// Colours
$pink-main: #F04E53;
$light-blue: #A8C3E5;
$yellow-main: #F3C736;
$hyperlink: #3733F0;
$button-bg: #5E1719;
$grey-dark: #272a2b;
$font-dark: #000;
$font-light: #fff;
$black: #000;
$white: #fff;
$bg: #fff;

// Graph
$graph-pink: #F04E53;
$graph-teal: #00A399;
$graph-orange: #EB6F0A;
$graph-blue: #3CA0BE;
$graph-purple: #BF559A;
$graph-lilac: #B185C1;
$graph-green: #879C3A;

// Mixins & Vars
$font-main: 'Rubik', sans-serif;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 600;
$border-radius: 0.5rem;

//Breakpoints
$tablet: 760px;
$max-width: 1180px;

//Mixins
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

//Mixins
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@mixin not-w-full-extended {
  left: unset;
  right: unset;
  width: unset;
  margin-left: unset;
  margin-right: unset;
}

@mixin a11y-highlight($pseudo-selector: focus-visible) {
  @if ($pseudo-selector == false) {
    box-shadow: 0 0 0 3px #2a2a2a, 0 0 0 6px #f0db3d;
    outline: none;
    @content;
  }
  @else {
    $pseudo-selector: #{':'}#{$pseudo-selector};
    &#{$pseudo-selector} {
      box-shadow: 0 0 0 3px #2a2a2a, 0 0 0 6px #f0db3d;
      outline: none;
      @content;
    }
  }
}
