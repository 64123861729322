.graph {
  &__grid {
    @extend .grid;
    align-items: center;
    border-top: 2px solid $button-bg;
  }

  &__information,
  &__olli,
  &__graph {
    grid-column-start: 1;
    grid-column-end: span 4;

    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }
  }

  &__olli-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__olli-explanation {
    @extend .hyperlink;
    text-align: right;
  }

  &__olli-heading {
    @extend h3;
  }

  &__graph {
    margin: 2rem 0 0;
    padding-top: 2rem;
    border-top: 1px solid $pink-main;

    * {
      font-family: $font-main;    
    }

    svg {
      max-width: 100%;
    }

    path[role="graphics-symbol"] {
      stroke: $white;
      stroke-width: 1px;
    }
  }
}

.publication {
  &__grid {
    @extend .grid;
    align-items: start;
    border-top: 2px solid $button-bg;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__information,
  &__callout {
    grid-column-start: 1;
    grid-column-end: span 4;
  }

  &__information {
    h2 {
      &::first-letter {
        text-transform: uppercase;
      }
      
      margin-top: 0.5rem;
    }
  }

  &__callout {
    border-left: 2px solid $pink-main;
    padding: 1rem; 

    .button {
      text-decoration: none;
      display: inline-block;
    }

    @media (min-width: $tablet) {
      grid-column-start: 5;
      grid-column-end: span 8;
    }
  }

  &__stats {
    padding-left: 1.5rem;
    list-style: none;

    li {
      margin-bottom: 0.5rem;
      line-height: 1.4;
    }
    
    li::before {
      content: "\2022";
      color: $pink-main;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
  }
}

// Olli Styling
.olli {
  padding: 1rem;
  border: 1px #A8C3E5 solid;
  border-radius: 5px;
}

// Olli styling when generated by looping the json
.olli--generated,
.olli-explainer {
  grid-column-start: 1;
  grid-column-end: span 4;

  @media (min-width: $tablet) {
    grid-column-end: span 12;
  }
}

.olli-explainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.olli--generated {
  @extend .olli;
}