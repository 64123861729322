// Base Stuff
@import './normalise.scss';
@import './lib.scss';
@import 'helpers/grid.scss';
@import 'helpers/buttons.scss';
@import 'helpers/dropdowns.scss';
@import 'helpers/patterns.scss';
@import 'helpers/focus.scss';
@import 'helpers/typography.scss';

//Components
@import 'components/header/header.scss';
@import 'components/footer/footer.scss';
@import 'components/hero/hero.scss';
@import 'components/data/graphs.scss';
@import 'components/dialogs/dialogs.scss';
@import 'components/tabs/tabs.scss';
@import 'components/content/index-content.scss';
@import 'components/map/map.scss';
@import 'components/chat/chat.scss';


body {
  font-size: 16px;
  font-family: $font-main;
  width: 100%;
  overflow-x: hidden;

  @media only screen and (min-width: $tablet) {
    font-size: 18px;
  }
}

main {
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  color: $font-dark;
  font-family: $font-main;
  font-weight: $weight-bold;
}

[role='presentation'] {
  pointer-events: none;
}

.display-none {
  display: none;
}