.tabs {
  grid-column-start: 1;
  grid-column-end: span 4;

  @media (min-width: $tablet) {
    grid-column-end: span 12;
  }

  &__tab {
    @extend .button;
    border-radius: 25px 25px 0 0;
    border: 2px solid $button-bg;
    border-bottom: none;

    &[aria-selected="false"] {
      background: $white;
      color: $button-bg;
      transition: all 0.2s;

      &:hover {
        background-color: $button-bg;
        color: $white;
      }
    }
  }
  
}


.survey {
  &__grid {
    @extend .grid;
    align-items: center;
    border-top: 2px solid $button-bg;
    margin-bottom: 1.5rem;
    padding-top: 2rem;
  }

  &__information {
    grid-column-start: 1;
    grid-column-end: span 4;

    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }
  }
}

.ratas-content,
.publications-content,
.cohorts-content,
.surveys-content {
  border-top: 2px solid $button-bg;
  padding-top: 0;

  &__data {
    border: 2px solid $graph-pink;
    padding: 1rem;
  }

}

.cohort {
  &__information {
    grid-column-start: 1;
    grid-column-end: span 4;

    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }
  }
}

.other-at {
  margin: 0.5rem 0 1rem;
  display: block;
}

.participants {
  display: inline-block;
  border-radius: 0;
  padding: 0.5rem;
  border: 2px solid $graph-green;
}

.at-tag-container {


  h3 { margin-bottom: 0.5rem; }

  p { margin-top: 0rem;}

  .at-tag {
    display: inline-block;
    border-radius: 0;
    padding: 0.5rem;
    border: 2px solid $graph-green;
    margin-right: 0.5rem;

    &.g {
      border: 2px solid $graph-pink;
    }

    &.h {
      border: 2px solid $graph-orange;
    }

    &.w {
      border: 2px solid $graph-blue;
    }

    &.p {
      border: 2px solid $graph-purple;
    }

    &.pda {
      border: 2px solid $graph-teal;
    }
  }
}

.no-data {
  grid-column-start: 1;
  grid-column-end: span 4;
  text-align: center;
  padding: 1rem;
  border: 2px solid $graph-pink;


  @media (min-width: $tablet) {
    grid-column-end: span 12;
  }
}