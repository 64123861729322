.get-started {
  grid-column-start: 1;
  grid-column-end: span 4;
  border: 4px solid $pink-main;
  text-align: center;
  padding: 0 1rem 2rem;
  margin: 4rem 0 0;

  .button {
    text-decoration: none;
    display: inline-block;

    &:first-of-type {
      margin-right: 1rem;
    }
  }

  p {
    margin-bottom: 1.5rem;
  }

  @media (min-width: $tablet) {
    grid-column-start: 3;
    grid-column-end: span 8;
  }
}

.text-block-container {
  grid-column-start: 1;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;

  @media (min-width: $tablet) {
    grid-column-end: span 12;
    justify-content: space-between;
    flex-direction: row;
  }
}

.text-block {
  border: 4px solid $pink-main;
  text-align: left;
  padding: 0 1rem 0.5rem;
  margin: 2rem 0 0;

  @media (min-width: $tablet) {
    flex-basis: 45%;
    margin: 2rem 0 0;
  }
}

.section {
  &--full {
    grid-column-start: 1;
    grid-column-end: span 4;
    background-color: #ddd;
    display: block;
    text-align: center;


    @media (min-width: $tablet) {
      grid-column-end: span 12;
    }
  }
}

.timeline {
  height: 2rem;
  padding: 4rem 0;
}

.blurb {
  grid-column-start: 1;
  grid-column-end: span 4;
  margin: 2rem 0;

  @media (min-width: $tablet) {
    grid-column-start: 1;
    grid-column-end: span 8;
  }
}

// Cards
.cards {
  grid-column-start: 1;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background-color: $yellow-main;
  margin-top: 2rem;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 50vw;
    height: 100%;
    background-color: inherit;
    z-index: -1;
  }

  &:before {
    left: -50vw;
  }

  &:after {
    right: -50vw;
  }

  @media (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-column-end: span 12;
  }

  &__heading {
    flex-basis: 100%;
    display: block;
    padding: 1rem 0;
    position: relative;
    margin-bottom: 0;

    @media (min-width: $tablet) {
      font-size: 2rem;
    }
  }


}

.card {
  padding: 1rem 0;
  position: relative;

  @media (min-width: $tablet) {
    flex-basis: 30%;
    padding: 1rem 0 0;
  }

  &__icon {
    max-height: 100px;
    padding: 0 1rem;

    @media (min-width: $tablet) {
      padding: 0;
    }
  }

  &__heading {
    padding: 0 1rem;
    line-height: 1.4;
    font-size: 24px;
    margin-bottom: 0;

    @media (min-width: $tablet) {
      padding: 0;
      max-width: 95%;
    }

    a {
      text-decoration: none;
      color: $black;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: "   \2192"
      }
    }
  }

  &__description {
    margin-bottom: 1rem;
    position: relative;
    padding: 1rem;

    @media (min-width: $tablet) {
      padding: 0 0 1rem 0;
    }
  }
}

//Pattern
.divider.pattern--yellow  {
  display: block;
  width: 100%;
  height: 4rem;
  grid-column-start: 1;
  grid-column-end: span 4;

      @media (min-width: $tablet) {

      grid-column-end: span 12;
  }
}
